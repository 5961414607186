import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class CustomerEmail extends Model {
    endpoint = 'customer_emails'

    fields = [
      new Field('id', 'ID').disabled().noFilterable(),
      new Field('email', 'Email'),
    ];

    clone = () => CustomerEmail;
}
