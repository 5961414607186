import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Relation from '@/dc-it/types/Relation'
import User from '@/dc-it/models/User'
import MultipleRelation from '@/dc-it/types/MutipleRelation'
import CustomerPhone from '@/dc-it/models/CustomerPhone'
import CustomerComment from '@/dc-it/models/CustomerComment'
import CustomerEmail from '@/dc-it/models/CustomerEmail'

export default class Customer extends Model {
    endpoint = 'customers'

    related = ['user', 'customer_phones', 'customer_comments', 'customer_emails']

    fields = [
      new Field('id', 'Código').disabled().noFilterable(),
      new Field('first_name', 'Nombre(s)'),
      new Field('last_name', 'Apellido(s)'),
      // new Field('email', 'Correo'),
      new Field('birthday', 'Fecha de nacimiento', 'date'),
      new Relation('user', 'Usuario', User, 'first_name').hide().disabled(),
      new MultipleRelation('customer_phones', 'Teléfonos', CustomerPhone, 'phone').hide().noFilterable(),
      new MultipleRelation('customer_comments', 'Comentarios', CustomerComment, 'comment').hide().noFilterable(),
      new MultipleRelation('customer_emails', 'Emails', CustomerEmail, 'email').hide().noFilterable().applyMask(() => {
        const emails = (this.getAttr('customer_emails', false))
        return emails && emails.length > 0 ? emails[0].getAttr('email') : ''
      }),
    ];

    getNameWithEmail() {
      return `${this.getAttr('first_name')} ${this.getAttr('last_name')} - ${this.getAttr('customer_emails')}`
    }

    clone = () => Customer
}
